<template>
	<main>
		<div class="bg-white border-bottom">
			<div class="header mb-0">
				<div class="container-fluid">
					<div class="header-body py-0 mt-4 border-0">
						<div class="row align-items-end pb-3">
							<div class="col">
								<h6 class="header-pretitle">Overview</h6>
								<h3 class="header-title">{{ currentCampaign }}</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid mt-3">
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div
							class="d-flex justify-content-end align-items-end border-bottom"
							>
							<div class="d-flex align-items-center p-3">
								<h4 class="pr-3">Turn on</h4>
								<label class="switch">
									<input
										v-model="checked"
										@change="toggleCampign"
										type="checkbox"
										/>
									<span class="slider round"></span>
								</label>
							</div>
						</div>
						<div class="card-header justify-content-between">
							<div
								class="input-group input-group-flush d-flex flex-row-reverse"
								>
								<input
									class="form-control list-search"
									type="search"
									placeholder="Search"
									v-model="search"
									@keyup.enter.prevent="fetchCampaignWinners(true)"
									/>
								<span class="input-group-text border-0">
									<i
										class="fe fe-search pointer"
										@keyup.enter.prevent="fetchCampaignWinners(true)"
										></i>
								</span>
							</div>

							<div class="col-auto d-flex align-items-center">
								<div>
									<v-datepicker
										style="width: 100%"
										v-model="filter.range"
										placeholder="Today"
										range
										></v-datepicker>
								</div>
								<div class="">
									<download-button
										class="cursor-pointer"
										:downloading="downloadingReport"
										label="Download Report"
										@download="downloadReport()"
										></download-button>
								</div>
							</div>
						</div>

						<b-table
							striped
							hover
							selectable
							show-empty
							responsive
							:items="campaignWinners"
							:fields="fields"
							:current-page="currentPage"
							:busy="fetchingCampaignWinners"
							id="my-table"
							>
							<template #table-busy>
								<div
									class="
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                  "
									>
									<div class="spinner-border" role="status"></div>
									<p class="text-center mt-2"><strong>Loading...</strong></p>
								</div>
							</template>

							<template #empty>
								<p class="text-center text-secondary">
									{{
										search
											? `No records found for search value: "${search}"`
											: 'No records available'
									}}
								</p>
							</template>
							<template #cell(date_time)="data">
								{{ data.item.created_at | date('dd/MM/yyyy') }}
							</template>

							<template #cell(name)="data">
								{{ data.item.user.fname + ' ' + data.item.user.lname }}
							</template>

							<template #cell(email)="data">
								{{ data.item.user.email }}
							</template>
							<template #cell(phone)="data">
								{{ data.item.user.phone }}
							</template>
							<template #cell(prize)="data">
								{{ data.item.reward.description, }}
							</template>
						</b-table>
						<div
							class="
                card-footer
                d-flex
                justify-content-between
                align-items-center
              "
							v-if="totalRecords"
							>
							<div>
								<b-pagination
									v-model="currentPage"
									:total-rows="totalRecords"
									:per-page="perPage"
									aria-controls="my-table"
									></b-pagination>
							</div>
							<div class="text-dark font-weight-bold">{{ tablePageText }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>
<script>
import moment from 'moment'
import { ExportToCsv } from 'export-to-csv'
import DownloadButton from '@/components/core/DownloadButton.vue'
import { extractErrorMessage } from '@/utils/helpers'
export default {
  name: 'ValentineCampaignWinnersList',
  data () {
    return {
      checked: '',
      currentCampaign: '',
      fields: [
        {
          key: 'date_time',
          label: 'DATE & TIME'
        },
        {
          key: 'name',
          label: 'NAME'
        },
        {
          key: 'email',
          label: 'EMAIL ADDRESS'
        },
        {
          key: 'phone',
          label: 'PHONE NUMBER'
        },
        {
          key: 'prize',
          label: 'PRIZE'
        }
      ],
      perPage: 20,
      currentPage: 1,
      totalRecords: 0,
      campaignWinners: [],
      fetchingCampaignWinners: true,
      downloadingReport: false,
      search: '',
      filter: {
        range: []
      }
    }
  },
  components: {
    DownloadButton
  },
  created () {
    this.fetchCampaignWinners()
    this.fetchSingleCampaign()
  },
  methods: {
    fetchSingleCampaign () {
      this.axios
        .get(`/v1/campaigns/${this.$route.params.campaignId}`)
        .then((res) => {
          if (res.data[0].is_active === 1) {
            this.checked = true
            this.currentCampaign = res.data[0].name
          } else {
            this.checked = false
          }
        })
        .catch(() => {
          this.$toastr.e('Something went wrong', 'Error')
        })
    },

    fetchCampaignWinners () {
      const dateRange = this.filter.range.map((date) =>
        moment(date).format('YYYY-MM-DD')
      )

      const metadata = true
      const limit = this.perPage
      const page = this.currentPage
      const query = this.search || ''
      let baseUrl = query
        ? `/v1/campaign/${this.$route.params.campaignId}/winners?limit=${limit}&page=${page}&metadata=${metadata}&search=${query}`
        : `/v1/campaign/${this.$route.params.campaignId}/winners?limit=${limit}&page=${page}&metadata=${metadata}`

      if (dateRange[0] || dateRange[1]) {
        baseUrl += `&start_date=${dateRange[0] ? dateRange[0] : ''}&end_date=${
          dateRange[1] ? dateRange[1] : ''
        }`
      }
      this.axios
        .get(baseUrl)
        .then((res) => {
          this.campaignWinners = res.data.data
          this.totalRecords = res.data?.metadata?.total
          this.fetchingCampaignWinners = false
          return this.campaignWinners
        })
        .finally(() => (this.fetchingCampaignWinners = false))
    },
    async downloadReport () {
      const dateRange = this.filter.range.map((date) =>
        moment(date).format('YYYY-MM-DD')
      )
      try {
        this.$Progress.start()
        this.downloadingReport = true
        const results = await this.axios.get(
          `/v1/campaign/${this.$route.params.campaignId}/winners?metadata=true&page=${this.currentPage}&limit=${this.totalRecords}&start_date=${dateRange[0]}&end_date=${dateRange[1]}`
        )
        const users = results.data.data
        this.$Progress.finish()
        const csvData = users.map((data) => {
          return {
            date_time: moment(data.created_at).format('YYYY-MM-DD'),
            name: data.user.fname + ' ' + data.user.lname,
            email: data.user.email,
            phone_number: data.user.phone,
            prize: data.reward.description
          }
        })

        const csvParams = {
          fieldSeparator: ',',
          filename:
            Array.isArray(this.dateRange) && !this.dateRange.length
              ? 'Valentine Winners List'
              : `Valentine Winners List for ${this.dateRange[0]} to ${this.dateRange[1]}`,
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          title:
            Array.isArray(this.dateRange) && !this.dateRange.length
              ? 'Valentine Winners List'
              : `Valentine Winners List for ${this.dateRange[0]} to ${this.dateRange[1]}`,
          useTextFile: false,
          useBom: true,
          headers: ['Date', 'Name', 'Email Address', 'Phone number', 'Prize']
        }

        const csvExporter = new ExportToCsv(csvParams)

        csvExporter.generateCsv(csvData)

        this.$swal({
          icon: 'success',
          title: 'Report downloaded',
          text: 'Report has been downloaded successfully',
          showCloseButton: true
        })
      } catch (error) {
        this.$Progress.fail()
      } finally {
        this.downloadingReport = false
      }
    },
    toggleCampign () {
      if (this.checked) {
        this.$swal({
          title: 'Are you sure?',
          text: 'You are about to turn on the valentine feature!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Proceed!'
        }).then((result) => {
          if (result.isConfirmed) {
            result.isConfirmed = true
            this.handleValentineCampign(result.isConfirmed)
          } else {
            this.checked = false
          }
        })
      }

      if (!this.checked) {
        this.$swal({
          title: 'Are you sure',
          text: 'You are about to turn off the valentine feature!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Proceed!'
        }).then((result) => {
          if (result.isConfirmed) {
            result.isConfirmed = false
            this.handleValentineCampign(result.isConfirmed)
          } else {
            this.checked = true
          }
        })
      }
    },

    async getCampignStatus () {
      try {
        const response = await this.axios.get('/v1/campaigns?status=active')
        if (response.data.enabled) {
          this.checked = true
        } else {
          this.checked = false
        }
      } catch (error) {
        const msg = extractErrorMessage(
          error,
          'An error occurred, please try again.'
        )
        this.$swal().showValidationMessage(msg)
      }
    },

    async handleValentineCampign (value) {
      try {
        await this.axios.patch(
          `/v1/campaigns/${this.$route.params.campaignId}`,
          {
            is_active: value
          }
        )
        if (value == true) {
          this.$swal(
            'Success!',
            'Valentine Campaign was successfully turned on',
            'success'
          )
          this.checked = true
        } else {
          this.$swal(
            'Success!',
            'Valentine Campaign was successfully turned off',
            'success'
          )
          this.checked = false
        }
      } catch (error) {
        if (value == true) {
          const msg = extractErrorMessage(
            error,
            'An error occurred, please try again.'
          )
          this.$swal().showValidationMessage(msg)
          this.checked = false
        } else {
          const msg = extractErrorMessage(
            error,
            'An error occurred, please try again.'
          )
          this.checked = true
          this.$swal().showValidationMessage(msg)
        }
      }
    }
  },
  computed: {
    tablePageText () {
      const cpSize = this.perPage * this.currentPage
      return this.totalRecords
        ? `Showing ${cpSize - this.perPage + 1} - ${Math.min(
            cpSize,
            this.totalRecords
          )} of ${this.totalRecords} records`
        : ''
    },
    filteredValentineWinners () {
      const search = this.search.toLowerCase()
      let winners = this.campaignWinners.filter((winner) => {
        return (
          winner?.fname?.toLowerCase().includes(search) ||
          winner?.lname?.toLowerCase().includes(search) ||
          winner?.email?.toLowerCase().includes(search) ||
          winner?.phone?.toLowerCase().includes(search) ||
          winner?.description?.toLowerCase().includes(search)
        )
      })
      if (this.dateFilterValid) {
        const dateRange = this.filter.range.map((date) =>
          moment(date).format('YYYY-MM-DD')
        )
        winners = winners.filter(
          (winner) =>
            winner?.date_created?.split(' ')[0] >= dateRange[0] &&
            winner?.date_created?.split(' ')[0] <= dateRange[1]
        )
      }
      return winners
    },
    // listCount() {
    //  let result =  ((this.pageNumber * 10)  - 10 ) + ( index + 1 )
    //  return result
    // },
    dateRange () {
      return this.filter.range.map((date) => moment(date).format('YYYY-MM-DD'))
    },
    dateFilterValid () {
      return (
        this.filter.range.length &&
        this.filter.range.filter((date) => !!date).length > 0
      )
    }
  },
  watch: {
    currentPage () {
      this.fetchCampaignWinners()
    },
    search (value) {
      if (!value) {
        this.fetchCampaignWinners(true)
      }
    },
    dateFilterValid () {
      this.fetchCampaignWinners(true)
    },
    dateRange (newValue, oldValue) {
      if (newValue[0] !== newValue[1] || oldValue[0] !== oldValue[1]) {
        this.fetchCampaignWinners(true)
      }
    },
    statusFilter () {
      this.fetchCampaignWinners(true)
    },
    'filter.range': {
      immediate: true,
      handler (value) {
        if (value[0] === null && value[1] == null) {
          this.fetchingCampaignWinners()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 24px;
  width: 26px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
